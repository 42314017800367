import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useUserContext } from '~src/context/UserContext/UserSessionContext';
import DefaultVideoList from './DefaultVideoList';
import ModernVideoList from './ModernVideoList';

const VideoList = ({ theme, playlists, ...rest }) => {
  const { session } = useUserContext();
  const { tishaBavHomeHeroFeaturedUrl } = session;
  const hasPlaylistMoreThanEightVideos = playlists.length > 8;
  const [isVideoExpanded, setIsPlaylistsExpanded] = useState(!hasPlaylistMoreThanEightVideos);

  const browser = typeof window !== 'undefined' && window;

  const handleExpansionClick = () => {
    setIsPlaylistsExpanded(true);
  };
  const shouldFilterPlaylists =
    browser.location &&
    browser.location.pathname &&
    browser.location.pathname === '/' &&
    tishaBavHomeHeroFeaturedUrl;

  const filteredPlaylists = shouldFilterPlaylists
    ? playlists.filter(playlist => playlist.url !== tishaBavHomeHeroFeaturedUrl)
    : [...playlists];

  const showPlaylists = isVideoExpanded ? filteredPlaylists : filteredPlaylists.slice(0, 8);

  if (theme === 'Modern Light') {
    return (
      <ModernVideoList
        {...rest}
        playlists={showPlaylists}
        enableShowMore={isVideoExpanded}
        onShowMoreClick={handleExpansionClick}
        showCardLabels={false}
      />
    );
  }
  if (theme === 'Modern Light Description Focused') {
    return (
      <ModernVideoList
        {...rest}
        playlists={showPlaylists}
        enableShowMore={isVideoExpanded}
        onShowMoreClick={handleExpansionClick}
        showCardLabels={false}
        descriptionFocused
      />
    );
  }
  return (
    <DefaultVideoList
      {...rest}
      playlists={showPlaylists}
      enableShowMore={isVideoExpanded}
      onShowMoreClick={handleExpansionClick}
    />
  );
};

VideoList.propTypes = {
  theme: PropTypes.string,
  sectionTitle: PropTypes.shape({
    text: PropTypes.string.isRequired,
    html: PropTypes.string.isRequired
  }).isRequired,
  sectionSubtitle: PropTypes.shape({
    text: PropTypes.string.isRequired,
    html: PropTypes.string
  }).isRequired,
  playlists: PropTypes.arrayOf(
    PropTypes.shape({
      author: PropTypes.string.isRequired,
      url: PropTypes.string,
      title: PropTypes.string,
      id: PropTypes.string,
      cover_image: PropTypes.shape({
        url: PropTypes.string.isRequired,
        alt: PropTypes.string
      }),
      description: PropTypes.shape({
        text: PropTypes.string,
        html: PropTypes.string
      }),
      label_is_new: PropTypes.string,
      label_is_audio: PropTypes.string,
      label_is_premium: PropTypes.string,
      label_is_producer: PropTypes.string,
      videos: PropTypes.arrayOf(
        PropTypes.shape({
          length_in_seconds: PropTypes.number.isRequired
        })
      ).isRequired
    })
  ).isRequired,
  compactMargins: PropTypes.bool
};

VideoList.defaultProps = {
  theme: 'Default',
  compactMargins: false
};

export default VideoList;

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Link from '~components/Link';
import clsx from '~styles/clsx';
import {
  LabelNew,
  LabelAudio,
  LabelPremium,
  LabelProducer,
  LabelOverlay
} from '~components/playlist-card/PlaylistLabels';

import { boolFlagFromString, formatDurationOfFirstVideoTishaBav } from '~utils/playlist-helpers';
import { lineFix } from '~utils/popup-helpers';
import { Typography, useMediaQuery, useTheme } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container: props => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 72,
    borderRadius: '12px',
    background: '#FFF',
    boxShadow: '0px 2px 0px 0px rgba(0, 0, 0, 0.10)',
    padding: '23px 28px',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      gap: 30,
      alignItems: props.descriptionFocused ? 'center' : 'flex-start'
    },
    [theme.breakpoints.down('xs')]: {
      gap: 20,
      padding: '15px 13px'
    }
  }),
  imageArea: {
    display: 'flex'
  },
  image: props => ({
    display: 'flex',
    borderRadius: '12px',
    objectFit: 'cover',
    width: props.descriptionFocused ? '430px' : '314px',
    height: props.descriptionFocused ? '246px' : '195px',
    [theme.breakpoints.down('sm')]: {
      width: '220px !important',
      height: '134px !important'
    },
    [theme.breakpoints.down('xs')]: {
      width: '167px !important',
      height: props.descriptionFocused ? '111px !important' : '101px !important'
    }
  }),
  contentArea: props => ({
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    maxWidth: '630px',
    [theme.breakpoints.down('sm')]: {
      gap: props.descriptionFocused ? 4 : 16
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: '130px'
    }
  }),
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8
  },
  titleText: {
    color: '#3A3938',
    fontSize: '24px',
    fontFamily: 'Inter',
    fontWeight: 700,
    lineHeight: '140%',
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '130%'
    }
  },
  infoText: props => ({
    color: '#808080',
    fontSize: '14px',
    fontFamily: 'Inter',
    fontWeight: 500,
    lineHeight: '140%',
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
      fontWeight: 400
    },
    [theme.breakpoints.down('sm')]: {
      display: props.descriptionFocused ? 'none' : 'inherit'
    }
  }),
  descriptionText: props => ({
    color: '#434343',
    fontSize: '14px',
    fontFamily: 'Inter',
    fontWeight: 500,
    lineHeight: '150%',
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: props.descriptionFocused ? 7 : 5,
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
      WebkitLineClamp: '3 !important',
      fontWeight: 400,
      display: props.descriptionFocused ? '-webkit-box' : 'none'
    }
  })
}));

const StyledLabelsOverlay = styled(LabelOverlay)`
  position: unset;
  margin-left: -4px;
  & > * {
    margin-left: 4px;
  }
`;

const ThemedVideoCard = ({
  showLabel,
  descriptionFocused,
  playlist: {
    url,
    title,
    description,
    cover_image,
    author,
    label_is_new,
    label_is_audio,
    label_is_premium,
    label_is_producer,
    videos
  }
}) => {
  const classes = useStyles({ descriptionFocused });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const isAudio = boolFlagFromString(label_is_audio);
  const isNew = boolFlagFromString(label_is_new);
  const isPremium = boolFlagFromString(label_is_premium);
  const isProducer = boolFlagFromString(label_is_producer);

  const infoText = useMemo(() => {
    const videoDurationFormatted = formatDurationOfFirstVideoTishaBav(videos[0].length_in_seconds);
    const partText = videos.length > 1 ? `Part 1 of ${videos.length}` : '';
    if (isMobile) {
      return [partText, videoDurationFormatted].filter(Boolean).join(' • ');
    }
    const durationText = `${videoDurationFormatted} video`;
    return [author, durationText, partText].filter(Boolean).join(' - ');
  }, [videos, author, isMobile]);

  return (
    <div className={classes.container}>
      <div className={classes.imageArea}>
        <Link to={url} underline="hover" color="textPrimary">
          <img
            src={cover_image.url}
            alt={cover_image.alt}
            className={clsx(classes.image, 'lazyload')}
          />
        </Link>
      </div>
      <Link to={url} underline="none" color="textPrimary">
        <div className={classes.contentArea}>
          <div className={classes.titleContainer}>
            <Typography component="h6" className={classes.titleText}>
              {lineFix(title)}
            </Typography>
            <Typography component="p" className={classes.infoText}>
              {infoText}
            </Typography>
          </div>
          <Typography component="p" className={classes.descriptionText}>
            {description.text}
          </Typography>
          {showLabel && (
            <StyledLabelsOverlay>
              {isNew && <LabelNew />}
              {isAudio && <LabelAudio />}
              {isPremium && <LabelPremium />}
              {isProducer && <LabelProducer />}
            </StyledLabelsOverlay>
          )}
        </div>
      </Link>
    </div>
  );
};
ThemedVideoCard.propTypes = {
  playlist: PropTypes.shape({
    author: PropTypes.string.isRequired,
    url: PropTypes.string,
    title: PropTypes.string,
    id: PropTypes.string,
    cover_image: PropTypes.shape({
      url: PropTypes.string.isRequired,
      alt: PropTypes.string
    }),
    description: PropTypes.shape({
      text: PropTypes.string,
      html: PropTypes.string
    }),
    label_is_new: PropTypes.string,
    label_is_audio: PropTypes.string,
    label_is_premium: PropTypes.string,
    label_is_producer: PropTypes.string,
    videos: PropTypes.arrayOf(
      PropTypes.shape({
        length_in_seconds: PropTypes.number
      })
    ).isRequired
  }),
  showLabel: PropTypes.bool,
  descriptionFocused: PropTypes.bool
};
ThemedVideoCard.defaultProps = {
  playlist: {},
  showLabel: true,
  descriptionFocused: false
};

export default ThemedVideoCard;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import RichText from '~components/RichText';
import Container from '~components/Container';
import Divider from '~components/Divider';
import VideoCard from '~components/VideoCard';

const StyledContainer = styled(Container)`
  margin-bottom: 40px;
  margin-top: 120px;
`;

const StyledExpandLine = styled.p`
  color: #458fce;
  cursor: pointer;
  margin: 0 auto;
  opacity: 0.87;
  font-size: 20px;
  margin-top: 60px;
  text-align: center;
  line-height: 30px;
  text-decoration: underline;
`;

const DefaultVideoList = ({
  sectionTitle,
  sectionSubtitle,
  playlists,
  enableShowMore,
  onShowMoreClick
}) => (
  <StyledContainer>
    <RichText html={sectionTitle.html} mode="videoListTitle" verticalSpacing={0} />
    <RichText html={sectionSubtitle.html} mode="videoListSubtitle" verticalSpacing={0} />

    {playlists &&
      playlists.map((playlistToShow, index) => (
        <React.Fragment key={`${playlistToShow.url} ${index + 2}`}>
          <VideoCard playlist={playlistToShow} />
          {index + 1 !== playlists.length && <Divider />}
        </React.Fragment>
      ))}

    {!enableShowMore && (
      <StyledExpandLine onClick={onShowMoreClick}>Show more videos</StyledExpandLine>
    )}
  </StyledContainer>
);

DefaultVideoList.propTypes = {
  enableShowMore: PropTypes.bool.isRequired,
  onShowMoreClick: PropTypes.func.isRequired,
  sectionTitle: PropTypes.shape({
    text: PropTypes.string.isRequired,
    html: PropTypes.string.isRequired
  }).isRequired,
  sectionSubtitle: PropTypes.shape({
    text: PropTypes.string.isRequired,
    html: PropTypes.string
  }).isRequired,
  playlists: PropTypes.arrayOf(
    PropTypes.shape({
      author: PropTypes.string.isRequired,
      url: PropTypes.string,
      title: PropTypes.string,
      id: PropTypes.string,
      cover_image: PropTypes.shape({
        url: PropTypes.string.isRequired,
        alt: PropTypes.string
      }),
      description: PropTypes.shape({
        text: PropTypes.string,
        html: PropTypes.string
      }),
      label_is_new: PropTypes.string,
      label_is_audio: PropTypes.string,
      label_is_premium: PropTypes.string,
      label_is_producer: PropTypes.string,
      videos: PropTypes.arrayOf(
        PropTypes.shape({
          length_in_seconds: PropTypes.number.isRequired
        })
      ).isRequired
    })
  ).isRequired
};

export default DefaultVideoList;

import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import RichText from '~components/RichText';
import Container from '~components/Container';
import ClickableText from '~components/buttons/ClickableText';
import ThemedVideoCard from '~components/ThemedVideoCard';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#F6F6F6'
  },
  container: {
    paddingTop: props => (props.compactMargins ? '24px' : '120px'),
    paddingBottom: props => (props.compactMargins ? '24px' : '87px'),
    alignItems: 'center !important',
    gap: '24px !important',
    [theme.breakpoints.down('xs')]: {
      gap: '16px !important',
      paddingTop: '80px',
      paddingBottom: '60px'
    }
  },
  titleContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '8px'
  },
  titleRichText: {
    '& > *': {
      color: '#000',
      textAlign: 'center',
      fontSize: '48px',
      fontFamily: 'Inter',
      fontWeight: 600,
      lineHeight: '113%',
      margin: 0,
      marginBlockStart: 0,
      marginBlockEnd: 0,
      [theme.breakpoints.down('xs')]: {
        fontSize: '32px',
        lineHeight: '117%'
      }
    }
  },
  subtitleRichText: {
    '& > *': {
      fontSize: '16px',
      lineHeight: '19px',
      opacity: 0.9,
      margin: 0,
      [theme.breakpoints.down('xs')]: {
        fontSize: '12px',
        lineHeight: '16px'
      }
    }
  },
  playlistContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
    maxWidth: props => (props.descriptionFocused ? '1200px' : '1066px'),
    paddingBottom: '16px',
    [theme.breakpoints.down('xs')]: {
      gap: '8px'
    }
  },
  showMoreButton: {
    borderRadius: '41px',
    backgroundColor: '#000',
    color: '#FFF',
    textAlign: 'center',
    fontSize: '18px',
    fontFamily: 'Roboto',
    fontWeight: 600,
    lineHeight: '18px',
    padding: '17px 55px',
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      backgroundColor: '#009FFF',
      fontWeight: 400
    }
  }
}));

const ModernVideoList = ({
  sectionTitle,
  sectionSubtitle,
  playlists,
  enableShowMore,
  onShowMoreClick,
  showCardLabels,
  compactMargins,
  descriptionFocused
}) => {
  const classes = useStyles({ compactMargins, descriptionFocused });

  return (
    <div className={classes.root}>
      <Container className={classes.container}>
        <div className={classes.titleContainer}>
          <RichText
            html={sectionTitle.html}
            externalClassName={classes.titleRichText}
            verticalSpacing={0}
          />
          {sectionSubtitle.html && (
            <RichText
              html={sectionSubtitle.html}
              externalClassName={classes.subtitleRichText}
              verticalSpacing={0}
            />
          )}
        </div>

        <div className={classes.playlistContainer}>
          {playlists &&
            playlists.map((playlistToShow, index) => (
              <React.Fragment key={`${playlistToShow.url} ${index + 2}`}>
                <ThemedVideoCard
                  playlist={playlistToShow}
                  showLabel={showCardLabels}
                  descriptionFocused={descriptionFocused}
                />
              </React.Fragment>
            ))}
        </div>

        {!enableShowMore && (
          <ClickableText className={classes.showMoreButton} onClick={onShowMoreClick}>
            Show more
          </ClickableText>
        )}
      </Container>
    </div>
  );
};

ModernVideoList.propTypes = {
  enableShowMore: PropTypes.bool.isRequired,
  onShowMoreClick: PropTypes.func.isRequired,
  showCardLabels: PropTypes.bool.isRequired,
  sectionTitle: PropTypes.shape({
    text: PropTypes.string.isRequired,
    html: PropTypes.string.isRequired
  }).isRequired,
  sectionSubtitle: PropTypes.shape({
    text: PropTypes.string.isRequired,
    html: PropTypes.string
  }).isRequired,
  playlists: PropTypes.arrayOf(
    PropTypes.shape({
      author: PropTypes.string.isRequired,
      url: PropTypes.string,
      title: PropTypes.string,
      id: PropTypes.string,
      cover_image: PropTypes.shape({
        url: PropTypes.string.isRequired,
        alt: PropTypes.string
      }),
      description: PropTypes.shape({
        text: PropTypes.string,
        html: PropTypes.string
      }),
      label_is_new: PropTypes.string,
      label_is_audio: PropTypes.string,
      label_is_premium: PropTypes.string,
      label_is_producer: PropTypes.string,
      videos: PropTypes.arrayOf(
        PropTypes.shape({
          length_in_seconds: PropTypes.number.isRequired
        })
      ).isRequired
    })
  ).isRequired,
  compactMargins: PropTypes.bool,
  descriptionFocused: PropTypes.bool
};

ModernVideoList.defaultProps = {
  compactMargins: false,
  descriptionFocused: false
};

export default ModernVideoList;
